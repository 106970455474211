import React, { useRef } from "react";
import styles from "./Furniture.module.scss";
import { useLanguage } from "../../context/Language";
import { getBrands, getSuppliers } from "../../utils/api";
import useFetchData from "../../utils/FetchData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
const Furniture = () => {
  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getBrands);
  const { data: suppliers } = useFetchData(getSuppliers);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <furnitureWrapper>
      <textContent>
        {/* <acc>
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
              stroke="#DD7D47"
              stroke-width="2"
            />
          </svg>
          {currentLanguage === "en"
            ? "Brands & Suppliers"
            : "Brendet & Furnitoret"}
        </acc> */}
        <h1>{currentLanguage === "en" ? "Suppliers" : "Furnitoret"}</h1>
      </textContent>
      <swiperWrapper>
        <leftArrow ref={prevRef}>
          <svg
            className="leftArrow"
            width="9"
            height="16"
            viewBox="0 0 9 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.822 7.63L1.652.16A.478.478 0 0 0 1.298 0a.478.478 0 0 0-.354.16l-.77.802a.516.516 0 0 0 0 .737L6.223 8 .175 14.297a.516.516 0 0 0 0 .737l.77.801c.102.107.22.16.353.16a.478.478 0 0 0 .354-.16l7.17-7.469a.517.517 0 0 0 0-.738z"
              fill="#131748"
              fill-rule="evenodd"
            />
          </svg>
        </leftArrow>

        <Swiper
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onInit={(swiper) => {
            // Ensure swiper updates navigation when component renders
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          modules={[Navigation]}
          className={styles.swiper}
          spaceBetween={30}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 6,
            },
          }}
        >
          {suppliers?.data?.map((brand, index) => (
            <SwiperSlide key={index} className={styles.swiperSlide}>
              <img
                src={brand.image.url}
                alt=""
                className={styles.sliderImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <rightArrow ref={nextRef}>
          <svg
            width="9"
            height="16"
            viewBox="0 0 9 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.822 7.63L1.652.16A.478.478 0 0 0 1.298 0a.478.478 0 0 0-.354.16l-.77.802a.516.516 0 0 0 0 .737L6.223 8 .175 14.297a.516.516 0 0 0 0 .737l.77.801c.102.107.22.16.353.16a.478.478 0 0 0 .354-.16l7.17-7.469a.517.517 0 0 0 0-.738z"
              fill="#131748"
              fill-rule="evenodd"
            />
          </svg>
        </rightArrow>
      </swiperWrapper>
      <textContent>
        {/* <acc>
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
              stroke="#DD7D47"
              stroke-width="2"
            />
          </svg>
          {currentLanguage === "en"
            ? "Brands & Suppliers"
            : "Brendet & Furnitoret"}
        </acc> */}
        <h1>{currentLanguage === "en" ? "Brands" : "Brendet"}</h1>
      </textContent>
      <logosWrapper>
        {data?.data?.map((data, index) => (
          <img src={data.image.url} alt="" key={index} />
        ))}
      </logosWrapper>
    </furnitureWrapper>
  );
};

export default Furniture;
